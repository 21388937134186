<template>
	<div class="footer" @click="openurl()" v-if="!isHome">京ICP备14010122号</div>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.name == "name";
    }
  },
  methods:{
    openurl() {
      window.open('https://beian.miit.gov.cn','_blank')
    }
  }
};
</script>

<style scoped>
.footer {
  cursor:pointer;
  height: 80px;
  background: #051015;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
}
</style>
