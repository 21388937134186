import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: '/home'
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
		meta: { title: '翰林院' }
	},
	{
		path: "/home/:id",
		name: "Home",
		component: Home,
		meta: { title: '翰林院' }
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
		meta: { title: '翰林院-关于我们' }
	},
	{
		path: "/showreels",
		name: "Showreels",
		component: () => import("../views/Showreels.vue"),
		meta: { title: '翰林院-关于我们' }
	},
	{
		path: "/news",
		name: "News",
		component: () => import("../views/News.vue"),

	},
	{
		path: "/news/detail/:id",
		name: "newsDetails",
		component: () => import("../views/NewsDetail.vue"),
	},
	{
		path: "/team",
		name: "Team",
		component: () => import("../views/Team.vue"),
		meta: { title: '翰林院-团队介绍' }
	},
	{
		path: "/teamDetail",
		name: "teamDetail",
		component: () => import("../views/TeamDetail.vue"),
		meta: { title: '翰林院-人物详情' }
	},
	{
		path: "/device",
		name: "Device",
		component: () => import("../views/Device.vue"),
		meta: { title: '翰林院-设备介绍' }
	},
	{
		path: "/partner",
		name: "Partner",
		component: () => import("../views/Partner.vue"),
		meta: { title: '翰林院-合作伙伴' }
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("../views/Contact.vue"),
		meta: { title: '翰林院-联系我们' }
	},
	{
		path: "/edit",
		name: "Edit",
		component: () => import("../views/Edit.vue"),
		meta: { title: '翰林院-编辑' }
	},
	{
		path: "/vfx",
		name: "VFX",
		component: () => import("../views/VFX.vue"),
		meta: { title: '翰林院-VFX' }
	}
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || '翰林院';
	document.documentElement.scrollTop = document.body.scrollTop = 0;
	next();
})

export default router;
