<template>
  <div class="home">
    <full-page v-if="options" :options="options" id="fullpage" ref="fullpage">
      <div class="section section1">
        <video loop autoplay muted ref="video-player" id="video-player" class="video-player"
          src="https://video.nextjoy.com.cn/2021/1/5/a66742655c72539bf5ff9df7851bec67.mp4"></video>
        <!-- 2021/1/5/1025073ce355de8a0d989ca23b71640e.mp4 -->
        <div class="text-wrap animated fadeIn">
          <div class="text">
            <p>{{ $t('lang.home.section1[0]') }}</p>
            <p>{{ $t('lang.home.section1[1]') }}</p>
          </div>
          <div class="scroll-icon">
            <span class="scroll-dot"></span>
          </div>
        </div>
      </div>
      <div class="section section2">
        <div class="wrap">
          <div class="text" :class="(sectionActive == 1 && sectionActive == 2) ? 'animated fadeIn' : ''">
            <h3 :class="$t(isFont) == 'true' ? 'font_cn' : ''">{{ $t('lang.home.section2[0]') }}</h3>
            <p>{{ $t('lang.home.section2[1]') }}</p>
            <p>{{ $t('lang.home.section2[2]') }}</p>
          </div>
          <div class="card-group">
            <div class="card" :class="(sectionActive == 1 && sectionActive == 2) ? 'animated zoomIn' : ''"
              v-for="item of cardList" :key="item.key">
              <div class="card-wrap">
                <img :src="item.img" alt="">
                <div class="text-wrap">
                  <div class="name" v-for="(p, i) of $t(item.name)" :key="i">{{ p }}</div>
                  <div class="view-btn">{{ $t('lang.home.card_btn') }}</div>
                </div>
                <div class="mouse-box" @mousemove.stop="cardHover" @mouseout.stop="cardLeave">
                  <router-link :to="item.path"></router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-icon">
            <span class="scroll-dot"></span>
          </div>
        </div>
        <!-- <div class="footer">京ICP备14010122号</div> -->
      </div>
      <div class="section fp-auto-height sectionFooter">
        <div class="footer" @click="openurl()">京ICP备14010122号</div>
      </div>
    </full-page>
    <div id="fp-nav">
      <div class="dot" :class="{ active: sectionActive == 0 }" @click="switchSection(0)"><span></span></div>
      <div class="dot" :class="{ active: sectionActive == 1 }" @click="switchSection(1)"><span></span></div>
    </div>
  </div>
</template>

<script>
import { throttle } from "@/utils/utils";
export default {
  name: "Home",
  data() {
    return {
      id: 1,
      options: {
        onLeave: this.onLeave
      },
      cardList: [
        {
          key: "1",
          img: require("@/assets/images/home_2_1.png"),
          name: "lang.home.card1",
          path: "/Device?type=0"
        },
        {
          key: "2",
          img: require("@/assets/images/home_2_2.png"),
          name: "lang.home.card2",
          path: "/Device?type=2"
        },
        {
          key: "3",
          img: require("@/assets/images/home_2_3.png"),
          name: "lang.home.card3",
          path: "/Device?type=1"
        },
        {
          key: "4",
          img: require("@/assets/images/home_2_4.png"),
          name: "lang.home.card4",
          path: "/VFX"
        },
        {
          key: "5",
          img: require("@/assets/images/home_2_5.png"),
          name: "lang.home.card5",
          path: "/Edit"
        },
        {
          key: "6",
          img: require("@/assets/images/home_2_6.png"),
          name: "lang.home.card6",
          path: ""
        }
      ],
      sectionActive: 0,
      isFont: 'lang.home.font'
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.$refs.fullpage.api.moveTo(this.id, 0);
    }
  },
  methods: {
    openurl() {
      window.open('https://beian.miit.gov.cn','_blank')
    },
    onLeave(origin, destination, direction) {
      this.sectionActive = destination.index;
      console.log(origin, destination, direction);
      if (destination.index == 0) {
        let player = this.$refs["video-player"];
        if (player) {
          player.play();
        }
      }
    },
    switchSection(index) {
      this.sectionActive = index;
      console.log(this.$refs.fullpage.api.moveTo);
      this.$refs.fullpage.api.moveTo(index + 1, 0);
    },
    cardHover: throttle(function (e) {
      event.stopPropagation();
      let cardEl = null;
      let card = "";
      e.path.forEach(i => {
        if (i.className == "card-wrap") {
          cardEl = i;
        }
        if (i.className == "card animated zoomIn") {
          card = i;
        }
      });
      if (cardEl) {
        let w = cardEl.clientWidth;
        let x = e.offsetX;
        let h = cardEl.clientHeight;
        let y = e.offsetY;
        let xdeg = (-(x - w / 2) / (w / 2)) * 10 + "deg";
        let ydeg = ((y - h / 2) / (h / 2)) * 10 + "deg";
        // console.log(width, x);
        if (card) {
          card.style.zIndex = 100;
        }
        cardEl.style.WebkitTransform = `scale(1, 1) perspective(400px) rotateX(${ydeg}) rotateY(${xdeg}) translateX(${"0"}) translateY(${"0"})  translateZ(${"30px"})`;
      }
    }, 100),
    cardLeave(e) {
      e.stopPropagation();
      let cardEl = null;
      let card = "";
      e.path.forEach(i => {
        if (i.className == "card-wrap") {
          cardEl = i;
        }
        if (i.className == "card animated zoomIn") {
          card = i;
        }
      });
      if (cardEl) {
        if (card) {
          card.style.zIndex = 98;
        }
        cardEl.style.WebkitTransform = `scale(1, 1) perspective(0) rotateX(0) rotateY(0) translateX(0) translateY(0)`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@function p2v($args) {
  @return unquote(($args/1920) * 100 + "vw");
}

.text-wrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.75);
  animation-duration: 5s;
  //   animation-delay: 0.5s;
}

.section {
  position: relative;
  overflow: hidden;
  cursor:pointer;
  // .text {
  //   font-family: "OpenSansRegular";
  // }
}

.section1 {
  .video-player {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .text {
    width: 100%;
    font-size: 40px;
    line-height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "OpenSansRegular";
  }
}

.scroll-icon {
  position: absolute;
  left: 50%;
  bottom: 56px;
  z-index: 102;
  transform: translateX(-50%);
  width: 34px;
  height: 52px;
  border: 3px solid #ffffff;
  border-radius: 17px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .scroll-dot {
    transform: translateY(8px);
    opacity: 1;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    background: #fff;
    animation: dot 2s linear 0.3s infinite;
  }
}

.footer {
  height: 80px;
  background: #051015;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
}

.section2 {
  .wrap {
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .text {
    padding: 0 22.18vw;
    width: 100%;
    text-align: left;
    animation-duration: 3s;
    animation-delay: 0.5s;

    h3 {
      font-size: 40px;
      line-height: 56px;
      margin: 10px auto;
      font-family: "OpenSansRegular";
    }

    .font_cn {
      font-weight: normal;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      font-family: "OpenSansLight";
    }
  }

  .card-group {
    // padding: 0 17.18vw;
    // padding: 0 100px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      flex: 1;
      height: 100%;
      cursor: pointer;
      animation-duration: 0.8s;
      animation-delay: 0.3s;
      position: relative;
      z-index: 98;

      &:hover {
        .text-wrap {
          background: rgba($color: #000000, $alpha: 0.3);
        }

        .name,
        .view-btn {
          transform: scale(1.1, 1.1);
        }
      }

      .card-wrap {
        transform-origin: center center;
        transform: perspective(624px) rotateX(0) rotateY(0) translateY(0) translateX(0) scale(1, 1);
        transition: all 0.27s ease-out;
        overflow: hidden;
        padding-top: 184.375%;
      }

      .mouse-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;

        >a {
          color: #fff;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      img {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      .text-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 96px;
      }

      .name {
        font-size: 24px;
        font-family: "OpenSansRegular";
        line-height: 30px;
        transition: transform 0.25s ease-out;
        transform-origin: center center;
        transform: perspective(400px) translateZ(10px);
      }

      .view-btn {
        margin-top: 24px;
        width: 116px;
        height: 42px;
        border: 1px solid #ffffff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.25s ease-out;
        transform-origin: center center;
        transform: perspective(400px) translateZ(10px);
        font-size: 14px;
        font-family: "OpenSansLight";
      }
    }
  }
}

@keyframes dot {
  0% {
    transform: translateY(8px);
    opacity: 1;
  }

  50% {
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}

#fp-nav {
  position: fixed;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  z-index: 99;
  overflow: hidden;

  .dot {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: block;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.4);
      transition: transform 0.1s ease-in-out, background-color 0.2s ease 0.3s,
        box-shadow 0.25s ease-out;
      cursor: pointer;

      &:hover {
        box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 1);
        background: transparent;
      }
    }
  }

  .dot.active {
    span {
      height: 18px;
      width: 18px;
      box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 1);
      background: transparent;
    }
  }
}

@media only screen and (max-width: 1024px) {
  video {
    display: none;
  }

  .section1 {
    .text {
      font-size: p2v(80);
    }
  }

  #fp-nav {
    display: none;
  }
}
</style>
