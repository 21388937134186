<template>
	<div id="top" :class="{'top-scroll':isScroll}">
		<div class="container">
			<router-link to="/">
				<img class="logo" src="@/assets/images/logo.png" alt="">
			</router-link>
			<img class="menu-open" @click="showMenu = true" src="@/assets/images/menu_btn.png" alt="">
		</div>
		<div class="menu-wrap" @click="closeWrap" :class="{open:showMenu}">
			<div class="menu">
				<img class="menu-close" src="@/assets/images/menu_close.png" alt="">
				<div class="menu-list" :class="$t(isFont)=='true'?'font_cn':''">
					<div class="menu-item" :class="{active:menuActive==item.path}" v-for="item of menuList" :key="item.key">
						<router-link :to="item.path">{{$t(item.name)}}</router-link>
					</div>
					<div class="menu-item menu-item_lang" :class="{active:$i18n.locale == 'en-US'}" @click="$i18n.locale = 'en-US'">
						<a>{{$t("lang.home.nav8")}}</a>
					</div>
					<div class="menu-item menu-item_lang" :class="{active:$i18n.locale == 'zh-CN'}" @click="$i18n.locale = 'zh-CN'" style="margin-top: 20px;">
						<a>{{$t("lang.home.nav9")}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { throttle } from "@/utils/utils";

export default {
  name: "top",
  data() {
    return {
      isScroll: false,
      showMenu: false,
      isFont:"lang.home.font",
      menuList: [
        {
          key: "1",
          path: "/about",
          name: "lang.home.nav1"
        },
        {
          key: "2",
          path: "/news",
          name: "lang.home.nav2"
        },
        {
          key: "3",
          path: "/home/2",
          name: "lang.home.nav3"
        },
        {
          key: "4",
          path: "/showreels",
          name: "lang.home.nav4"
        },
        {
          key: "5",
          path: "/team",
          name: "lang.home.nav5"
        },
        {
          key: "6",
          path: "/partner",
          name: "lang.home.nav6"
        },
        {
          key: "7",
          path: "/contact",
          name: "lang.home.nav7"
        }
      ]
    };
  },
  mounted() {
    document.addEventListener("scroll", throttle(this.scrollHandle, 50));
  },
  methods: {
    scrollHandle() {
      this.isScroll = document.documentElement.scrollTop > 100;
    },
    closeWrap(e) {
      if (
        e.target == document.querySelector(".menu-close") ||
        e.target == document.querySelector(".menu-wrap")
      ) {
        this.showMenu = false;
        return;
      }
    }
  },
  computed: {
    menuActive() {
      return this.$route.path;
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.path);
      this.showMenu = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@function p2v($args) {
  @return unquote(($args/1920) * 100 + "vw");
}
#top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  transition: all 0.5s;
  .container {
    transition: all 0.5s;
    height: 164px;
    width: 100%;
    max-width: 100%;
    padding: 0 p2v(50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
    .logo {
      width: 150px;
      transition: all 0.5s;
    }
    .menu-open {
      width: 24px;
    }
  }
}
#top.top-scroll {
  background: rgba($color: #061319, $alpha: 0.7);
  .container {
    height: 100px;
    .logo {
      width: 130px;
    }
  }
}
.menu-wrap {
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  z-index: 100;
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 278px;
    height: 100vh;
    background: #0d222b;
    .menu-close {
      width: 24px;
      position: absolute;
      right: 100px;
      top: 69px;
      cursor: pointer;
    }
    .menu-list {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 60px;
      padding-right: 60px;
      font-size: 24px;
      overflow: hidden;
      font-family: "OpenSansLight";
      font-weight: bold;
      .menu-item {
        width: auto;
        text-align: left;
        margin-top: 20px;
        overflow: hidden;
        a {
          color: #fff;
          position: relative;
          display: inline-block;
          //   line-height: 1.5;
          // font-weight: normal;
          &::after {
            content: "";
            transition: all 0.5s ease-out;
            display: block;
            width: 0;
            left: 0;
            border-bottom: 3px solid transparent;
            transform-origin: left;
            pointer-events: none;
          }
        }
      }
      .menu-item_lang {
        height: 30px;
        cursor: pointer;
        font-size: 18px;
        margin-top: 60px;
        opacity: 0.7;
      }
      .menu-item:hover,
      .active {
        opacity: 1;
        a {
          &::after {
            width: 100%;
            border-color: #fff;
          }
        }
      }
    }
    .font_cn{
      font-weight:normal;
    }
  }
}
.open {
  right: 0;
}
@media only screen and (max-width: 1024px) {
  #top {
    .container {
      height: p2v(328);
      padding: 0 p2v(50);
      .logo {
        width: p2v(300);
      }
      .menu-open {
        width: p2v(48);
      }
    }
  }
  #top.top-scroll {
    background: rgba($color: #061319, $alpha: 0.7);
    .container {
      height: p2v(328);
      .logo {
        width: p2v(300);
      }
    }
  }
}
</style>
