export const lang = {
	"home": {
		"section1": ["We unite creativity with technology to", "push the boundaries of what entertainment can be."],
		"section2": ["WHAT WE DO", "We unite creativity with technology to", "push the boundaries of what entertainment can be."],
		"card1": ["DIGITAL", "INTERMEDIATE"],
		"card2": ["MUSIC"],
		"card3": ["SOUND DESIGN", "& MIXING"],
		"card4": ["VISUAL", "EFFECTS"],
		"card5": ["EDIT"],
		"card6": ["PLATFORM &", "LIVE STREAMING"],
		"card_btn": 'View more',
		"nav1": "ABOUT",
		"nav2": "NEWS",
		"nav3": "WHAT WE DO",
		"nav4": "SHOWREELS",
		"nav5": "DEPARTMENTS",
		"nav6": "PARTNER",
		"nav7": "CONTACT",
		"nav8": "EN",
		"nav9": "CN",
		"font": "false"
	},
	"contact": {
		"header1": 'Contact & Location',
		"address1": 'ADDRESS',
		"address2": "Hanlin cinema, No.9 Wan'an, hanheyuan Road, Haidian District, Beijing",
		"address3": [
			"LinHong : 13501393060 ",
			"ZhengFushe: 13911685378 ",
			"LiYongji: 18701002942"
		]
	},
	"partner": {
		"header1": 'Co-work',
	},
	"about": {
		"header1": 'About Company',
		"isShow":'en'
	},
	"news": {
		"header1": 'NEWS & Press releases',
		"title1":'"May Flowers and Five Songs" New Songs Press Conference',
		"title2":'Documentary documentary "The Time Is Imprinted on My Heart" presentation ceremony and the opening of Hanlinyuan Patriotic Education Base was held in Beijing',
		"title3":'The opening ceremony of Hanlin Yuan Music Recording Studio was held in Beijing',
	},
	"edit": {
		"header1": "EDIT",
		"header2": 'Film editing',
		"text1": 'Editing',
		"text2": ["We work on your movie with the mind of a craftsman, making a plain stone into gemstones.",
			"Confirm the birth of the gem. "]

	},
	"VFX": {
		"header1": "VFX",
		"header2": 'Visual effects',
		"text1": 'Title Design & VFX',
		"text2": ["Design the opening sequence of a movie from a new perspective with brand new ideas. "]
	},
	"showreels": {
		"text1": "Yong Gi-Lee 's Showreels",
		"text2": "Moses On The Plain"
	},
	"team": {
		"header1": 'Excellent team and members',
		"name1":"Yong Gi-Lee",
		"text1":["Artistic Director","Production Supervisor"],
		"text2":["作曲家, ⾳乐总监"],
		"text3":["D.I Supervisor"]
	},
	"device":{
		"name1":"DIGITAL INTERMEDIATE",
		"text1": [
			"Korea's top composers and potential composers will put a soul into the music you want.",
			"Music planning, composing, writing, recording,",
			"everything will be made here."
		],
		"text21":["Music directors who have participated in many other works other than movies and dramas will join us."],
		"text22":[
			"Following the visual elements, music directors like magicians who immerse your ears with music.",
			"Music will complete your movie and drama one step higher."
		],
		"text3": [
			"Leave your film to a world-class digital colorist.",
			"See with your own eyes that your movie is changing in level."
		],
	}
	
}