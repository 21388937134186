export const lang = {
	"home": {
		"section1": ["我们把创造⼒与技术结合起来","来推动娱乐的界限. "],
		"section2": ["我们的业务", "我们把创造⼒与技术结合起来", "来推动娱乐的界限."],
		"card1": ["数码", "中间片"],
		"card2": ["音乐"],
		"card3": ["声⾳设计", "与 混⾳"],
		"card4": ["视觉", "效果"],
		"card5": ["电影剪辑"],
		"card6": ["平台 与", "直播"],
		"card_btn": '查看更多',
		"nav1": "关于我们",
		"nav2": "新闻",
		"nav3": "我们的业务",
		"nav4": "作品展览",
		"nav5": "部门",
		"nav6": "合作伙伴",
		"nav7": "联系我们",
		"nav8": "EN",
		"nav9": "CN",
		"font":"true"
	},
	"contact": {
		"header1": 'Contact & Location',
		"address1": '地址',
		"address2": "北京市-海淀区-瀚河园路万安⾥9号翰林院影业",
		"address3": [
			"林 虹: 13501393060 ",
			"郑复社: 13911685378 ",
			"李庸基: 18701002942"
		]
	},
	"partner": {
		"header1": 'Co-work',
	},
	"about": {
		"header1": 'About Company',
		"isShow":'cn'
	},
	"news": {
		"header1": 'NEWS & Press releases',
		"title1": '"May Flowers and Five Songs" New Songs Press Conference',
		"title2": 'Documentary documentary "The Time Is Imprinted on My Heart" presentation ceremony and the opening of Hanlinyuan Patriotic Education Base was held in Beijing',
		"title3": 'The opening ceremony of Hanlin Yuan Music Recording Studio was held in Beijing',
	},
	"edit": {
		"header1": "EDIT",
		"header2": 'Film editing',
		"text1": 'Editing',
		"text2": ["把电影的最终完成交由世界著名的调色师。",
			"他们会让你亲眼见证奇迹的发生"]

	},
	"VFX": {
		"header1": "VFX",
		"header2": '视觉效果',
		"text1": 'Title Design & VFX',
		"text2": ["以全新视角、全新创意、来设计电影开场。 "]
	},
	"showreels": {
		"text1": "李庸基的作品展览",
		"text2": "平原上的摩西"
	},
	"team": {
		"header1": 'Excellent team and members',
		"name1":"李庸基",
		"text1":["艺术总监","Production Supervisor"],
		"text2":["作曲家, ⾳乐总监"],
		"text3":["D.I Supervisor"]
	},
	"device":{
		"name1":"数字中间片",
		"text1":[
			"由韩国最顶级、最具潜力的作曲家精英团队共同打造，为音乐注入灵魂。",
			"企划、作曲、作词、录音等",
			"全部流程都将在这里完成。"
		],
		"text21":["由参与过众多影视剧作品及其他作品的音乐导演将共同打造。"],
		"text22":["除去影像带来的视觉盛宴以外，同时在听觉上也会沉浸在音乐导演如魔术师般打造的电影世界中。","使电影和电视剧更上一层楼。"],
		"text3": [
			"把电影的最终完成交由世界著名的调色师。",
			"他们会让你亲眼见证奇迹的发生。 "
		],
	}
}