<template>
	<div id="app">
		<Header />
		<!-- <keep-alive> -->
		<router-view />
		<!-- </keep-alive> -->
		<Footer />
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "hly",
  components: {
    Header,
    Footer
  }
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  user-select: none; /* CSS3属性 */
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus,
video,
video:hover,
video:active,
video:visited,
video:link,
video:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}
p,
h3 {
  margin: 0;
}
body {
  margin: 0;
  min-height: 100vh;
}
html,
body,
ul,
li,
p,
h2,
span {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #061319;
  overflow: hidden;

  .header {
    position: relative;
    img {
      width: 100%;
      min-height: 780px;
    }
    .inner {
      position: absolute;
      left: 18.2vw;
      top: 50%;
      transform: translateY(-50%);
      p {
        text-align: left;
        font-size: 20px;
        font-family: "OpenSansLight";
        line-height: 1;
      }
      p:first-of-type {
        font-size: 50px;
        margin: 20px 0;
        font-family: "OpenSansRegular";
      }
    }
  }
}
</style>
