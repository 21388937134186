import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import './utils/animate.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import './assets/font/font.css';
Vue.use(VueAwesomeSwiper)
// import './utils/swiper.min.css';

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	// 默认语言
	locale: 'en-US',
	// 引入对应的语言包文件
	messages: {
		'zh-CN': require('@/assets/lang/zh.js'),
		'en-US': require('@/assets/lang/en.js')
	}
});

// 引用fullpage 插件
// import 'fullpage.js/vendors/scrolloverflow'
// import './fullpage.scrollHorizontally.min'
import './utils/fullpage.css'
import VueFullPage from './utils/vue-fullpage'
Vue.use(VueFullPage);

Vue.config.productionTip = false;

new Vue({
	router,
	i18n,
	//   store,
	render: h => h(App)
}).$mount("#app");
